var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"team"},[(_vm.$apollo.queries.team.loading)?_c('Loader',{attrs:{"color":"#FF035A","size":"50"}}):_c('div',{staticClass:"content"},[(_vm.canUpdateTeam)?_c('div',{staticClass:"invite-container"},[_c('div',{staticClass:"title"},[(_vm.team.company)?_c('span',[_vm._v(" Invite user to "+_vm._s(_vm.team.company.name)+" ")]):_c('span',[_vm._v("Invite your team")]),_c('div',{staticClass:"plus-icon"},[_c('img',{attrs:{"src":require("../assets/icons/Plus-Accent.svg")}})])]),_c('div',{staticClass:"invite"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"text-input",attrs:{"type":"email","placeholder":"Email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),_c('CustomSelect',{attrs:{"val":_vm.role,"default":"Representative","options":[
            { value: 'representative', label: 'Representative' },
            { value: 'artist', label: 'Artist' }
          ],"no-margin":""},on:{"setValue":value => (_vm.role = value)}}),_c('CustomSelect',{attrs:{"val":_vm.access,"default":"Contributor","options":[
            { value: 'contributor', label: 'Contributor' },
            { value: 'owner', label: 'Owner' }
          ],"no-margin":""},on:{"setValue":value => (_vm.access = value)}}),(!_vm.team.company)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.companyName),expression:"companyName"}],staticClass:"text-input",attrs:{"type":"text","placeholder":"Company"},domProps:{"value":(_vm.companyName)},on:{"input":function($event){if($event.target.composing)return;_vm.companyName=$event.target.value}}}):_vm._e(),_c('button',{staticClass:"primary",attrs:{"type":"submit","disabled":!_vm.invitationValid || _vm.submitting},on:{"click":_vm.invite}},[_vm._v(" "+_vm._s(_vm.submitting ? "Submitting..." : "Invite User")+" ")])],1)]):_vm._e(),_c('div',{staticClass:"teammates"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Email")]),_c('th',[_vm._v("Relationship")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Contract Access")])])]),_c('tbody',_vm._l((_vm.team.users),function({ id, role, access, user }){return _c('tr',{key:id},[_c('td',[_vm._v(_vm._s(user.email))]),_c('td',[_vm._v(_vm._s(_vm._f("capitalize")(role)))]),_c('td',[_vm._v(_vm._s(_vm._f("capitalize")(access)))]),(_vm.removing.includes(id))?_c('td',[_c('Loader',{attrs:{"color":"#FF035A","size":"24"}})],1):(_vm.canUpdateTeam && _vm.userId !== user.id)?_c('td',[_c('div',{staticClass:"remove-container"},[_c('button',{staticClass:"remove",on:{"click":function($event){return _vm.removeMember(id)}}},[_c('span',{staticClass:"minus"})])])]):_vm._e()])}),0)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }